
const state = {
	embeded: false
}

const getters = {
	isEmbeded: state => {
		return state.embeded
	}
}

const mutations = {
	/** @param {boolean} data */
	set: (state, data) => {
		if (state.embeded)
		{
			// once set to embeded it will not change
			// use unset to reset
			return
		}
		state.embeded = data
	},

	unset: (state) => {
		state.embeded = false
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
