import memoize from 'lodash/memoize'

import events from 'studio-shared/dataLoader/events'

const keyForDate = require('studio-shared/utils/dateToYearMonthDay')

import fetchClassTypes from 'studio-shared/dataLoader/fetchClassTypes'
import fetchClassLevels from 'studio-shared/dataLoader/fetchClassLevels'
import fetchTeachers from 'studio-shared/dataLoader/fetchTeachers'
import fetchStudios from 'studio-shared/dataLoader/fetchStudios'

import fetchAndListenForDancers from 'studio-shared/dataLoader/fetchAndListenForDancers'
import fetchAndListenForDancerEvents from 'studio-shared/dataLoader/fetchAndListenForDancerEvents'
import fetchAndListenForClasses from 'studio-shared/dataLoader/fetchAndListenForClasses'
import fetchAndListenForDancersToClasses from 'studio-shared/dataLoader/fetchAndListenForDancersToClasses'
import fetchAndListenForMissedClasses from 'studio-shared/dataLoader/fetchAndListenForMissedClasses'
import fetchAndListenForMakeups from 'studio-shared/dataLoader/fetchAndListenForMakeups'
import fetchAndListenForExtraCredits from 'studio-shared/dataLoader/fetchAndListenForExtraCredits'
import fetchAndListenForFreeClasses from 'studio-shared/dataLoader/fetchAndListenForFreeClasses'

import fetchAndListenForMissedClassesByDate from 'studio-shared/dataLoader/fetchAndListenForMissedClassesByDate'
import fetchAndListenForMakeupsByDate from 'studio-shared/dataLoader/fetchAndListenForMakeupsByDate'
import fetchAndListenForFreeClassesByDate from 'studio-shared/dataLoader/fetchAndListenForFreeClassesByDate'
import fetchAndListenForDancerEventsByClass from 'studio-shared/dataLoader/fetchAndListenForDancerEventsByClass'

import fetchAndListenForClosures from 'studio-shared/dataLoader/fetchAndListenForClosures'
import fetchAndListenForSubstitutes from './fetchAndListenForSubstitutes'

const loader = {
	fetchClassTypes: fetchClassTypes,
	fetchClassLevels: fetchClassLevels,
	fetchTeachers: fetchTeachers,
	fetchStudios: fetchStudios,

	fetchAndListenForDancers: memoize(fetchAndListenForDancers),
	fetchAndListenForDancerEvents: memoize(fetchAndListenForDancerEvents),
	fetchAndListenForClasses: memoize(fetchAndListenForClasses),
	fetchAndListenForDancersToClasses: fetchAndListenForDancersToClasses,
	fetchAndListenForMissedClasses: memoize(fetchAndListenForMissedClasses),
	fetchAndListenForMakeups: memoize(fetchAndListenForMakeups),
	fetchAndListenForExtraCredits: memoize(fetchAndListenForExtraCredits),
	fetchAndListenForFreeClasses: memoize(fetchAndListenForFreeClasses),

	fetchAndListenForMissedClassesByDate: memoize(fetchAndListenForMissedClassesByDate, keyForDate),
	fetchAndListenForMakeupsByDate: memoize(fetchAndListenForMakeupsByDate, keyForDate),
	fetchAndListenForFreeClassesByDate: memoize(fetchAndListenForFreeClassesByDate, keyForDate),
	fetchAndListenForDancerEventsByClass: memoize(fetchAndListenForDancerEventsByClass),

	fetchAndListenForClosures: memoize(fetchAndListenForClosures),
	fetchAndListenForSubstitutes: memoize(fetchAndListenForSubstitutes),

	once: function (eventName) {
		return events.once(eventName)
	}
}

export default loader
