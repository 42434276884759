import {
	firestore as db,
	collections
} from '../firebase'

import memoize from 'lodash/memoize'
import map from 'lodash/map'

const toMapByUid = require('studio-shared/utils/toMapByUid')

const getLookups = memoize(() => {
	return Promise.all([
		db.collection(collections.ClassTypes).get(),
		db.collection(collections.Studios).get(),
		db.collection(collections.ClassLevels).get(),
		db.collection(collections.Teachers).get(),
	])
	.then(results => {
		return {
			classTypes: toMapByUid(results[0]),
			studios: toMapByUid(results[1]),
			classLevels: toMapByUid(results[2]),
			teachers: toMapByUid(results[3])
		}
	})
})

const fillPropertyData = (item, lookups) => {
	item.classType = lookups.classTypes[item.classType]
	item.classLevel = lookups.classLevels[item.classLevel]
	item.instructor = lookups.teachers[item.instructor]
	item.studio = lookups.studios[item.studio]

	return item
}

const classesFromUid = (uids) => {
	return Promise.all(map(uids, uid => {
		return db.collection(collections.Classes).doc(uid).get().then(snap => {
			if (!snap.exists)
			{
				return {}
			}

			const data = snap.data()
			data.uid = snap.id
			return getLookups().then(lookups => fillPropertyData(data, lookups))
		})
	}))
}

export {
	getLookups,
	fillPropertyData,
	classesFromUid
}

export default {
	getLookups: getLookups,
	fillPropertyData: fillPropertyData,
	classesFromUid: classesFromUid
}
