import eventsByClass from 'studio-shared/store/modules/eventsByClass'
import missedClassesByDate from 'studio-shared/store/modules/missedClassesByDate'
import makeupsByDate from 'studio-shared/store/modules/makeupsByDate'
import freeClasses from 'studio-shared/store/modules/freeClasses'
import freeClassesByDate from 'studio-shared/store/modules/freeClassesByDate'
import extraCredits from 'studio-shared/store/modules/extraCredits'
import closures from 'studio-shared/store/modules/closures'

import embeded from './modules/embeded'
import substitutes from './modules/substitutes'

import store from 'studio-shared/store'

store.registerModule('eventsByClass', eventsByClass)
store.registerModule('missedClassesByDate', missedClassesByDate)
store.registerModule('makeupsByDate', makeupsByDate)
store.registerModule('freeClasses', freeClasses)
store.registerModule('freeClassesByDate', freeClassesByDate)
store.registerModule('extraCredits', extraCredits)
store.registerModule('closures', closures)

store.registerModule('embeded', embeded)
store.registerModule('substitutes', substitutes)

export default store
