var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.invalidLink ? _c("div", [_vm._m(1)]) : _vm._e(),
          _vm.state === "done"
            ? _c("div", [
                _c("article", { staticClass: "message is-success" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "message-body" }, [
                    _vm._m(3),
                    _c("br"),
                    _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/schedule/makeups/" + _vm.uid } },
                          [
                            _vm._v(
                              "Click here to schedule your makeups when you are ready!"
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _vm._m(4),
                    _c("br"),
                    _c("p", [
                      _c("a", { on: { click: _vm.handleEmail } }, [
                        _vm._v("Just let us know!")
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.dancer && _vm.state !== "done"
            ? _c(
                "div",
                [
                  _c("h3", { staticClass: "is-size-3" }, [
                    _vm._v(" Hi " + _vm._s(_vm.dancer.firstName) + ", "),
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(" Let's confirm which class you'll be missing: ")
                    ])
                  ]),
                  _c("br"),
                  _vm._l(_vm.appointments, function(item) {
                    return [
                      !_vm.isLoading && _vm.appointments.length > 0
                        ? _c("div", { key: item.id, staticClass: "box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "is-clearfix",
                                staticStyle: { height: "0px" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tags is-pulled-right" },
                                  [
                                    _vm.isToday(item)
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-info" },
                                          [_vm._v("Today")]
                                        )
                                      : _vm._e(),
                                    item.classData.makeupUid
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-dark" },
                                          [_vm._v("Makeup")]
                                        )
                                      : item.classData.freeUid
                                      ? _c(
                                          "span",
                                          { staticClass: "tag is-warning" },
                                          [_vm._v("Free")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _c("div", { staticClass: "columns" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  class: {
                                    "is-1": !item.missed,
                                    "is-2": item.missed
                                  },
                                  staticStyle: { "margin-right": "5px" }
                                },
                                [
                                  !item.missed
                                    ? _c(
                                        "div",
                                        { staticClass: "field" },
                                        [
                                          _c("b-switch", {
                                            attrs: { size: "is-medium" },
                                            model: {
                                              value: _vm.missState[item.id],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.missState,
                                                  item.id,
                                                  $$v
                                                )
                                              },
                                              expression: "missState[item.id]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.missed
                                    ? _c(
                                        "span",
                                        { staticClass: "tag is-warning" },
                                        [_vm._v("Confirmed")]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "column",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formatClass(item)) + " "
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  }),
                  _c("div", { staticClass: "is-clearfix" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-large is-primary is-pulled-right",
                        class: { "is-loading": _vm.state === "working" },
                        attrs: { disabled: !_vm.hasSelected },
                        on: { click: _vm.handleConfirm }
                      },
                      [_vm._v(" Confirm ")]
                    )
                  ]),
                  _c("div", { staticClass: "has-text-centered" }, [
                    _c("br"),
                    _c("p", [
                      _c(
                        "button",
                        {
                          staticClass: "button is-text",
                          on: { click: _vm.handleScheduleMakeups }
                        },
                        [
                          _vm._v(
                            "Click here to schedule makeups when you are ready!"
                          )
                        ]
                      )
                    ])
                  ])
                ],
                2
              )
            : _vm._e(),
          _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "hero has-background-grey-dark" }, [
      _c("div", { staticClass: "hero-body" }, [
        _c("div", { staticClass: "container has-text-centered" }, [
          _c("img", {
            staticStyle: { "max-height": "150px" },
            attrs: { src: require("../../assets/logo.png") }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("article", { staticClass: "message is-warning" }, [
      _c("div", { staticClass: "message-header" }, [
        _c("h1", { staticClass: "is-size-3" }, [
          _vm._v("Looks like you have a broken link...")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-header" }, [
      _c("h1", { staticClass: "is-size-3" }, [_vm._v("You are all set!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Thanks for letting us know you'll be missing class.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Don't forget -")]),
      _vm._v(
        " if this time no longer works for you, we have lots of classes available and are happy to help you find a better fit. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }