var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.embeded
      ? _c("section", { staticClass: "hero has-background-grey-dark" }, [
          _vm._m(0)
        ])
      : _vm._e(),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "is-size-3" }, [
            _vm._v(" Schedule Makeup Classes ")
          ]),
          _c("br"),
          _vm.state === "done"
            ? _c("div", [
                _c("article", { staticClass: "message is-success" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "message-body" }, [
                    _c(
                      "p",
                      [
                        _c(
                          "prima-toggle",
                          {
                            attrs: { dancer: _vm.dancer },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prima",
                                  fn: function() {
                                    return [
                                      _vm._v(" You've scheduled a "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.classTitle(
                                              _vm.selectedAppointment.classData
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" class for "),
                                      _c("u", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDateFull(
                                              _vm.selectedAppointment.date
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        " with " +
                                          _vm._s(
                                            _vm.selectedAppointment.classData
                                              .instructor.name
                                          ) +
                                          " at " +
                                          _vm._s(
                                            _vm.selectedAppointment.classData
                                              .studio.name
                                          ) +
                                          " "
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              97239173
                            )
                          },
                          [
                            _vm._v(" You've scheduled a "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.classTitle(
                                    _vm.selectedAppointment.classData
                                  )
                                )
                              )
                            ]),
                            _vm._v(" makeup for "),
                            _c("u", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDateFull(
                                    _vm.selectedAppointment.date
                                  )
                                )
                              )
                            ]),
                            _vm._v(
                              " with " +
                                _vm._s(
                                  _vm.selectedAppointment.classData.instructor
                                    .name
                                ) +
                                " at " +
                                _vm._s(
                                  _vm.selectedAppointment.classData.studio.name
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _vm.creditsRemaining > 0 || _vm.isPrima
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "button is-outlined is-medium is-dark is-fullwidth",
                            on: { click: _vm.handleScheduleAnother }
                          },
                          [
                            _c(
                              "prima-toggle",
                              {
                                attrs: { dancer: _vm.dancer },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prima",
                                      fn: function() {
                                        return [_vm._v(" Schedule Another ")]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1724803764
                                )
                              },
                              [
                                _vm._v(
                                  " Schedule Another with " +
                                    _vm._s(_vm.creditsRemaining) +
                                    " " +
                                    _vm._s(
                                      _vm.pluralOrSingle(
                                        "credit",
                                        _vm.creditsRemaining
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " Great job! You've scheduled all your makeup credits. "
                          )
                        ])
                  ])
                ])
              ])
            : _vm.state === "canceled"
            ? _c("div", [
                _c("article", { staticClass: "message is-info" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "message-body" }, [
                    _c(
                      "p",
                      [
                        _c(
                          "prima-toggle",
                          {
                            attrs: { dancer: _vm.dancer },
                            scopedSlots: _vm._u([
                              {
                                key: "prima",
                                fn: function() {
                                  return [
                                    _vm._v(" We've canceled your class for "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.classTitle(
                                            _vm.canceledAppointment.classData
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" on "),
                                    _c("u", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateFull(
                                            _vm.canceledAppointment.date
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(
                                      " with " +
                                        _vm._s(
                                          _vm.canceledAppointment.classData
                                            .instructor.name
                                        ) +
                                        " at " +
                                        _vm._s(
                                          _vm.canceledAppointment.classData
                                            .studio.name
                                        ) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _vm._v(" We've canceled your makeup for "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.classTitle(
                                    _vm.canceledAppointment.classData
                                  )
                                )
                              )
                            ]),
                            _vm._v(" on "),
                            _c("u", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDateFull(
                                    _vm.canceledAppointment.date
                                  )
                                )
                              )
                            ]),
                            _vm._v(
                              " with " +
                                _vm._s(
                                  _vm.canceledAppointment.classData.instructor
                                    .name
                                ) +
                                " at " +
                                _vm._s(
                                  _vm.canceledAppointment.classData.studio.name
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-outlined is-medium is-dark is-fullwidth",
                        on: { click: _vm.handleScheduleAnother }
                      },
                      [_vm._v(" Back to Schedule ")]
                    )
                  ])
                ])
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("lookup-dancer", {
                      ref: "lookupDancer",
                      attrs: { dancer: _vm.dancer },
                      on: {
                        clear: _vm.clearAll,
                        dancerFound: _vm.handleDancerFound
                      }
                    })
                  ],
                  1
                ),
                _vm.dancer
                  ? _c(
                      "div",
                      {
                        staticClass: "box",
                        class: { fixed: _vm.attachSecondStep }
                      },
                      [
                        _c("div", { staticClass: "columns is-multiline" }, [
                          _c(
                            "div",
                            {
                              staticClass: "column",
                              class: {
                                "is-1": !_vm.selectedAppointment,
                                "is-full-mobile": _vm.selectedAppointment,
                                "is-1-desktop": _vm.selectedAppointment,
                                "is-1-tablet": _vm.selectedAppointment
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "tag is-rounded is-large",
                                  class: {
                                    "is-primary": !_vm.selectedAppointment
                                  }
                                },
                                [_vm._v(" 2 ")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "4.25%" } },
                                [
                                  _c(
                                    "prima-toggle",
                                    {
                                      attrs: { dancer: _vm.dancer },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prima",
                                            fn: function() {
                                              return [
                                                !_vm.selectedAppointment
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "is-size-4 is-hidden-desktop is-hidden-tablet",
                                                        staticStyle: {
                                                          margin: "0px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Schedule as many classes as you'd like! Your Prima Pass gives you unlimited classes. "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        2286305680
                                      )
                                    },
                                    [
                                      _vm.selectedAppointment
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "is-size-5 is-hidden-desktop is-hidden-tablet",
                                              staticStyle: { margin: "0px" }
                                            },
                                            [
                                              _vm._v(" Using "),
                                              _c("u", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedAppointment
                                                      .classData.classType
                                                      .credits
                                                  )
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.pluralOrSingle(
                                                      "credit",
                                                      _vm.selectedAppointment
                                                        .classData.classType
                                                        .credits
                                                    )
                                                  ) +
                                                  " for: "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.selectedAppointment
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "is-size-4 is-hidden-desktop is-hidden-tablet",
                                              staticStyle: { margin: "0px" }
                                            },
                                            [
                                              _vm._v(" You have "),
                                              _c("u", [
                                                _vm._v(
                                                  _vm._s(_vm.creditsRemaining)
                                                )
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.pluralOrSingle(
                                                      "credit",
                                                      _vm.creditsRemaining
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c("div", { staticClass: "column" }, [
                            _vm.selectedAppointment
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "is-size-5 is-hidden-mobile",
                                    staticStyle: { "margin-bottom": "20px" }
                                  },
                                  [
                                    _c(
                                      "prima-toggle",
                                      { attrs: { dancer: _vm.dancer } },
                                      [
                                        _vm._v(" Using "),
                                        _c("u", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedAppointment.classData
                                                .classType.credits
                                            )
                                          )
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.pluralOrSingle(
                                                "credit",
                                                _vm.selectedAppointment
                                                  .classData.classType.credits
                                              )
                                            ) +
                                            " for: "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.selectedAppointment
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "is-size-4 is-hidden-mobile",
                                    staticStyle: { "margin-bottom": "20px" }
                                  },
                                  [
                                    _c(
                                      "prima-toggle",
                                      {
                                        attrs: { dancer: _vm.dancer },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prima",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    " Schedule as many classes as you'd like! Your Prima Pass gives you unlimited classes. "
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1042464613
                                        )
                                      },
                                      [
                                        _vm._v(" You have "),
                                        _c("u", [
                                          _vm._v(_vm._s(_vm.creditsRemaining))
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.pluralOrSingle(
                                                "credit",
                                                _vm.creditsRemaining
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedAppointment
                              ? _c("div", { staticClass: "field" }, [
                                  _c("div", { staticClass: "card" }, [
                                    _c("div", { staticClass: "card-content" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "columns is-multiline is-gapless is-mobile"
                                        },
                                        [
                                          _vm._m(3),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-7-mobile is-10-desktop is-9-tablet"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.classTitle(
                                                      _vm.selectedAppointment
                                                        .classData
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm._m(4),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-7-mobile is-10-desktop is-9-tablet"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedAppointment
                                                      .classData.studio.name
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm._m(5),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-7-mobile is-10-desktop is-9-tablet"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedAppointment
                                                      .classData.instructor.name
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm._m(6),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column is-7-mobile is-10-desktop is-9-tablet"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDateFull(
                                                      _vm.selectedAppointment
                                                        .date
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "columns is-multiline is-mobile"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column is-1-desktop is-1-tablet is-2-mobile",
                                        staticStyle: { "line-height": "35px" }
                                      },
                                      [_vm._v(" Which: ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column is-3-desktop is-4-tablet is-10-mobile"
                                      },
                                      [
                                        _c(
                                          "b-select",
                                          {
                                            staticStyle: {
                                              display: "inline-block"
                                            },
                                            attrs: {
                                              placeholder: "Select a class"
                                            },
                                            model: {
                                              value:
                                                _vm.filter.selectedClassOption,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "selectedClassOption",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.selectedClassOption"
                                            }
                                          },
                                          _vm._l(
                                            _vm.filter.classOptions,
                                            function(option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option.value,
                                                  domProps: {
                                                    value: option.name
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(option.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column is-1-desktop is-1-tablet is-2-mobile",
                                        staticStyle: { "line-height": "35px" }
                                      },
                                      [_vm._v(" When: ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column is-3-desktop is-4-tablet is-10-mobile"
                                      },
                                      [
                                        _c(
                                          "b-select",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "130px"
                                            },
                                            attrs: {
                                              placeholder: "Select a time"
                                            },
                                            model: {
                                              value: _vm.filter.selectedWeek,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "selectedWeek",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.selectedWeek"
                                            }
                                          },
                                          _vm._l(
                                            _vm.filter.weekOptions,
                                            function(option) {
                                              return _c(
                                                "option",
                                                {
                                                  key: option.value,
                                                  domProps: {
                                                    value: option.value
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(option.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.dancer && !_vm.selectedAppointment
                  ? _c(
                      "div",
                      {
                        staticClass: "columns is-multiline",
                        class: { "fixed-padding": _vm.attachSecondStep }
                      },
                      [
                        !_vm.isLoading && _vm.filteredAppointments.length === 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "column is-full content has-text-grey has-text-centered"
                              },
                              [
                                _c("br"),
                                _vm.filter.selectedClassOption === "All Classes"
                                  ? _c("span", [
                                      _vm._v(
                                        "There are no available classes " +
                                          _vm._s(
                                            _vm.filter.weekOptions[
                                              _vm.filter.selectedWeek
                                            ].name
                                          )
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "There are no available " +
                                          _vm._s(
                                            _vm.filter.selectedClassOption
                                          ) +
                                          " classes " +
                                          _vm._s(
                                            _vm.filter.weekOptions[
                                              _vm.filter.selectedWeek
                                            ].name
                                          )
                                      )
                                    ]),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    on: { click: _vm.removeClassTypeFilter }
                                  },
                                  [_vm._v(" Back to All Classes ")]
                                )
                              ]
                            )
                          : _vm._l(_vm.filteredAppointments, function(item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "column is-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "box",
                                      staticStyle: { "min-height": "277px" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "title is-size-4" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.titleForClass(
                                                  item.classData
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "subtitle is-size-5" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(item.classData.dayOfWeek)
                                            )
                                          ]),
                                          _vm._v(" with "),
                                          item.substitute
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "strike",
                                                    {
                                                      staticClass:
                                                        "has-text-grey"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.classData
                                                            .instructor.name
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.substitute
                                                          .instructor.name
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.classData.instructor
                                                        .name
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      ),
                                      _c("div", { staticClass: "content" }, [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.classData.startTime.replace(
                                                  " ",
                                                  ""
                                                )
                                              ) +
                                              " on "
                                          ),
                                          _c("u", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDateNoDay(item.date)
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            " at " +
                                              _vm._s(
                                                item.classData.studio.name
                                              ) +
                                              " "
                                          )
                                        ]),
                                        item.conflict
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tag is-warning is-large",
                                                staticStyle: { width: "100%" }
                                              },
                                              [_vm._v(" Already Scheduled ")]
                                            )
                                          : item.makeupSpaceLeft <= 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tag is-danger is-large",
                                                staticStyle: { width: "100%" }
                                              },
                                              [_vm._v(" Full ")]
                                            )
                                          : _vm.isStudioClosed(item.date)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tag is-light is-large",
                                                staticStyle: { width: "100%" }
                                              },
                                              [_vm._v(" Studio Closed ")]
                                            )
                                          : !_vm.isPrima &&
                                            item.classData.classType.credits >
                                              _vm.creditsRemaining
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tag is-light is-large",
                                                staticStyle: { width: "100%" }
                                              },
                                              [
                                                _vm._v(
                                                  " Need " +
                                                    _vm._s(
                                                      item.classData.classType
                                                        .credits -
                                                        _vm.creditsRemaining
                                                    ) +
                                                    " More " +
                                                    _vm._s(
                                                      _vm.pluralOrSingle(
                                                        "Credit",
                                                        item.classData.classType
                                                          .credits -
                                                          _vm.creditsRemaining
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "button is-primary is-medium is-outlined has-text-dark is-fullwidth",
                                                attrs: {
                                                  disabled:
                                                    item.conflict ||
                                                    item.makeupSpaceLeft <= 0
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleSelect(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "prima-toggle",
                                                  {
                                                    attrs: {
                                                      dancer: _vm.dancer
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prima",
                                                          fn: function() {
                                                            return [
                                                              _vm._v(
                                                                " Schedule Class "
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Select for " +
                                                        _vm._s(
                                                          item.classData
                                                            .classType.credits
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.pluralOrSingle(
                                                            "credit",
                                                            item.classData
                                                              .classType.credits
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                      ]),
                                      item.conflict
                                        ? _c(
                                            "div",
                                            { staticClass: "is-clearfix" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button is-danger is-small is-pulled-right",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleCancelAppointment(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._m(7, true),
                                                  _c("span", [
                                                    _vm._v(" Cancel ")
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        : item.makeupSpaceLeft > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "is-clearfix" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "tag is-info is-pulled-right"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.makeupSpaceLeft
                                                      ) +
                                                      " spot" +
                                                      _vm._s(
                                                        item.makeupSpaceLeft > 1
                                                          ? "s available"
                                                          : " left"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.selectedAppointment
                  ? _c("div", [
                      _vm.scheduleError
                        ? _c(
                            "article",
                            {
                              staticClass: "message is-danger",
                              staticStyle: {
                                "margin-right": "0.75rem",
                                "margin-top": "10px"
                              }
                            },
                            [_vm._m(8)]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button is-medium is-primary is-fullwidth",
                              class: { "is-loading": _vm.state === "working" },
                              on: { click: _vm.handleSchedule }
                            },
                            [_vm._v(" Schedule ")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "field" }, [
                        _c("div", { staticClass: "control" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "button is-outlined is-text is-fullwidth",
                              attrs: { disabled: _vm.state === "working" },
                              on: { click: _vm.handleStartOver }
                            },
                            [_vm._v(" Start Over ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
          _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container has-text-centered" }, [
        _c("img", {
          staticStyle: { "max-height": "150px" },
          attrs: { src: require("../../assets/logo.png") }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-header" }, [
      _c("strong", { staticClass: "is-size-4" }, [_vm._v("You're all set!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-header" }, [
      _c("strong", { staticClass: "is-size-4" }, [_vm._v("You're all set!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column is-5-mobile is-2-desktop is-3-tablet" },
      [_c("strong", [_vm._v("Class:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column is-5-mobile is-2-desktop is-3-tablet" },
      [_c("strong", [_vm._v("Studio:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column is-5-mobile is-2-desktop is-3-tablet" },
      [_c("strong", [_vm._v("Instructor:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column is-5-mobile is-2-desktop is-3-tablet" },
      [_c("strong", [_vm._v("Date:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small" }, [
      _c("i", { staticClass: "fas fa-times" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-header" }, [
      _c("strong", [
        _vm._v(
          "There was a problem scheduling your makeup. Please try again later or try another class."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }