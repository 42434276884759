import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScrollToBottom extends Vue
{
	protected scrollToBottom()
	{
		setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 10)
	}
}
