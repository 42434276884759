







import { Component, Mixins, Prop } from 'vue-property-decorator'

import IsPrima from '@/mixins/IsPrima'

@Component
export default class PrimaToggle extends Mixins(IsPrima)
{
	@Prop() dancer!: IDancer

	protected getDancer()
	{
		return this.dancer
	}
}

