







































import { Component, Mixins, Watch } from 'vue-property-decorator'

import {
	firestore as db,
	collections
} from '../../firebase'

import reduce from 'lodash/reduce'
import map from 'lodash/map'

import Formats from '@/mixins/Formats'
import AddDancerUidToUrl from '@/mixins/AddDancerUidToUrl'
import EmbededState from '@/mixins/EmbededState'

import LookupDancer from '@/components/LookupDancer.vue'

import loader from '@/dataLoader'

import store from '@/store'

import { dateKeyFormat } from '@/utils/dateKeyFormat'

const loadClassDependents = (uid: string) => {
	return Promise.all([
		loader.fetchAndListenForMissedClasses(uid),
		loader.fetchAndListenForFreeClasses(uid)
	])
}

const getScrollTop = () => (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop

@Component({
	components: {
		LookupDancer
	}
})
export default class ViewCredits extends Mixins(Formats, AddDancerUidToUrl, EmbededState)
{
	private isLoading = false
	private state = 'idle'

	private dancer: IDancer | null = null

	get uid()
	{
		if (this.dancer)
		{
			return this.dancer!.uid
		}
		return this.$route.params.dancerUid
	}

	get missedClasses()
	{
		const list = this.$store.getters.missedClassesForDancer(this.uid)
		
		const classes = map(list,
			(data: any) => {
				data.classData = this.$store.getters.class(data.missedClass)

				return data
			}
		)

		return classes
	}

	get missedClassesCreditsCount()
	{
		return reduce(this.missedClasses, (sum: number, obj: any) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get extraCredits()
	{
		const list = this.$store.getters['extraCredits/forDancer'](this.uid)

		return list
	}

	get extraCreditsCount()
	{
		return reduce(this.extraCredits, (sum: number, obj: any) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get creditsRemaining()
	{
		return this.missedClassesCreditsCount + this.extraCreditsCount
	}

	protected getDancer()
	{
		return this.dancer
	}

	mounted()
	{
		if (!this.uid)
		{
			return
		}

		if (this.dancer)
		{
			return
		}

		this.isLoading = true

		// @ts-ignore
		this.$refs.lookupDancer.lookupDancerByUid(this.uid).then(() => {
			loader.fetchAndListenForDancerEvents(this.uid)
		})
		.catch((err: Error) => {
			console.log(err)
			this.isLoading = false
		})
	}

	private handleDancerFound(dancer: IDancer)
	{
		this.dancer = dancer

		if (!this.$route.params.dancerUid)
		{
			this.addDancerUidToUrl(this.uid)
		}

		loader.fetchAndListenForMakeups(this.uid)
		loader.fetchAndListenForExtraCredits(this.uid)
		loader.fetchAndListenForDancerEvents(this.uid)

		this.isLoading = true

		if (this.$store.getters.classes.length > 0)
		{
			loadClassDependents(this.uid)
				.then(() => this.isLoading = false)
				.catch(err => {
					console.log(err)
					this.isLoading = false
				})
		}
		else
		{
			loader.once('classes-loaded').then(() => {
				return loadClassDependents(this.uid)
			})
			.then(() => this.isLoading = false)
			.catch((err: Error) => {
				console.log(err)
				this.isLoading = false
			})
		}
	}

	private clearAll()
	{
		this.$router.push('/credits')
		this.dancer = null
	}

	private pluralOrSingle(base: string, count: number)
	{
		if (count === 1)
		{
			return base
		}

		return base + 's'
	}
}
