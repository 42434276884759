import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Welcome from './views/Welcome.vue'

import UpdatePayment from './views/payments/UpdatePayment.vue'
import MissClassAuto from './views/missedClass/MissClassAuto.vue'
import MissClassDisplay from './views/missedClass/MissClassDisplay.vue'
import MissClassSelector from './views/missedClass/MissClassSelector.vue'

import ScheduleMakeups from './views/makeups/ScheduleMakeups.vue'
import ViewCredits from './views/makeups/ViewCredits.vue'

Vue.use(Router)

export default new Router({
	scrollBehavior: function (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/welcome/:dancerUid?',
			name: 'welcome',
			component: Welcome
		},
		{
			path: '/payments/update/:dancerUid?',
			name: 'update-payment',
			component: UpdatePayment
		},
		{
			path: '/schedule/miss/class/display/:dancerUid?',
			name: 'miss-class-display',
			component: MissClassDisplay
		},
		{
			path: '/schedule/miss/class/:dancerUid?',
			name: 'miss-class',
			component: MissClassSelector
		},
		{
			// intended for use from reminder emails
			// this only shows the classes scheduled for today
			path: '/miss/class/:email',
			name: 'miss-class-auto',
			component: MissClassAuto
		},

		{
			path: '/schedule/makeups/:dancerUid?',
			name: 'schedule-makeups',
			component: ScheduleMakeups
		},
		{
			path: '/credits/:dancerUid?',
			name: 'view-credits',
			component: ViewCredits
		},
		
		// {
		//   path: '/about',
		//   name: 'about',
		//   // route level code-splitting
		//   // this generates a separate chunk (about.[hash].js) for this route
		//   // which is lazy-loaded when the route is visited.
		//   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	]
})
