<template>
<div>

<section v-if="!embeded" class="hero has-background-grey-dark">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../assets/logo.png" style="max-height: 150px" />
		</div>
	</div>
</section>

<section class="section">
<div class="container">
	<div class="box" v-show="!(uid && isLoading)">
		<lookup-dancer ref="lookupDancer" :hideStepWhenFound="true" :dancer="dancer" @clear="clearAll" @dancerFound="handleDancerFound" />
	</div>

	<div class="columns is-multiline" v-if="dancer">
		<div class="column is-half">
			<div class="box" style="min-height: 200px">
				<p class="is-size-4">Need to miss a class?</p>
				<p style="min-height: 50px">
					Click the button below to let us know which class you'll be missing.
				</p>
				<br>
				<button @click="handleScheduleMissedClass" class="button is-primary is-outlined has-text-dark is-medium is-fullwidth">
					Schedule Missed Classes
				</button>
			</div>
		</div>
		<div class="column is-half">
			<div class="box" style="min-height: 200px">
				<p class="is-size-4">Have makeup credits to use?</p>
				<p style="min-height: 50px">
					Click the button below to schedule your makeup classes.
				</p>
				<br>
				<button @click="handleScheduleMakeups" class="button is-primary is-outlined has-text-dark is-medium is-fullwidth">
					Schedule Makeups
				</button>
			</div>
		</div>
	</div>
</div>
</section>

</div>
</template>

<script>
import LookupDancer from '@/components/LookupDancer'

import AddDancerUidToUrl from '@/mixins/AddDancerUidToUrl'
import EmbededState from '@/mixins/EmbededState'

import loader from '@/dataLoader'

export default {
	mixins: [
		AddDancerUidToUrl,
		EmbededState
	],
	data: function () {
		return {
			dancer: null,

			isLoading: false
		}
	},
	mounted: function () {
		if (!this.uid)
		{
			return
		}

		this.isLoading = true

		this.$refs.lookupDancer.lookupDancerByUid(this.uid).then(() => {
			this.isLoading = false
		})
		.catch(err => {
			console.log(err)
			this.isLoading = false
		})
	},
	computed: {
		uid: function () {
			if (!this.dancer && !this.$route.params.dancerUid)
			{
				return null
			}

			if (!this.dancer)
			{
				return this.$route.params.dancerUid
			}

			return this.dancer.uid
		}
	},
	methods: {
		handleDancerFound: function (dancer) {
			this.dancer = dancer

			loader.fetchAndListenForDancerEvents(this.dancer.uid)

			if (!this.$route.params.dancerUid)
			{
				this.addDancerUidToUrl(this.uid)
			}
		},
		clearAll: function () {
			this.$router.push({
				path: '/welcome',
				query: this.$route.query
			})
			this.dancer = null
		},

		createUrlForEmbed: function (path) {
			const location = window.location
			return location.protocol + '//' + location.host + path
		},

		goToPath: function (path) {
			if (this.embeded)
			{
				const url = this.createUrlForEmbed(path)
				window.open(url)
				return
			}
			this.$router.push(path)
		},

		handleScheduleMissedClass: function (evt) {
			evt.preventDefault()

			const path = '/schedule/miss/class/' + this.uid
			this.goToPath(path)
		},
		handleScheduleMakeups: function (evt) {
			evt.preventDefault()

			const path = '/schedule/makeups/' + this.uid
			this.goToPath(path)
		}
	},
	components: {
		LookupDancer
	}
}
</script>

<style scoped>

</style>
