






























































import { Component, Vue, Prop } from 'vue-property-decorator'

import {
	firestore as db,
	collections
} from '../firebase'

import toLower from 'lodash/toLower'

const defaultLookup = () => {
	return {
		error: false,
		lastName: '',
		email: '',
		state: 'idle'
	}
}

@Component
export default class LookupDancer extends Vue
{
	@Prop() dancer!: IDancer
	@Prop() hideStepWhenFound!: boolean
	
	private lookup = defaultLookup()

	private lookupDancerByUid(uid: string)
	{
		return new Promise(resolve => {
			db.collection(collections.Dancers).doc(uid).onSnapshot(snap => {
				if (!snap.exists)
				{
					return Promise.reject(new Error(`Could not find dancer with uid (${uid})`))
				}

				const dancer = snap.data()!
				dancer.uid = snap.id

				this.$emit('dancerFound', dancer)

				resolve(dancer)
			})
		})
	}

	private handleLookupDancer(evt: MouseEvent)
	{
		evt.preventDefault()

		this.lookup.state = 'working'
		this.lookup.error = false

		const query = db.collection(collections.DancerQueryData)
			.where('lastName', '==', toLower(this.lookup.lastName))
			.where('email', '==', toLower(this.lookup.email))

		query.get().then(snap => {
			if (snap.empty)
			{
				// can't find user so show error
				this.lookup.error = true
				this.lookup.state = 'idle'
				return Promise.reject(new Error('could not find dancer with info'))
			}

			const doc = snap.docs[0]
			
			return this.lookupDancerByUid(doc.id)
		})
		.then(() => {
			this.lookup.state = 'success'
		})
	}

	private handleClear()
	{
		this.lookup = defaultLookup()

		this.$emit('clear')
	}
}
