<template>
<div>
<section v-if="!embeded" class="hero has-background-grey-dark">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../../assets/logo.png" style="max-height: 150px" />
		</div>
	</div>
</section>
<section class="section">
	<div class="container">
		<div v-if="invalidLink">
			<article class="message is-warning">
				<div class="message-header">
					<h1 class="is-size-3">Looks like you have a broken link...</h1>
				</div>
				<div class="message-body">
					If you are trying to update your payment information, <a class="has-text-dark" @click="openEmail">please email us</a> and we will send you the correct link!
				</div>
			</article>
		</div>
		<div v-if="!invalidLink">
			<div v-if="success">
				<article class="message is-success">
					<div class="message-header">
						<h1 class="is-size-3">Success!</h1>
					</div>
					<div class="message-body">
						<strong>Your payment information has been successfully updated!</strong>
					</div>
				</article>
			</div>
			<div v-else>
				<h1 class="is-size-3">Update Payment Information</h1>
				<br>
				<!-- select dancer -->
				<div class="box">
					<lookup-dancer ref="lookupDancer" :dancer="dancer"
						@clear="clearAll"
						@dancerFound="handleDancerFound"
						hideStepWhenFound
					/>
				</div>
				<template v-if="dancer">
					<p>
						Securely update your credit card information using the form below.
					</p>
					<br>
					<div v-if="error.message" class="notification is-danger">
						<strong>{{ error.message }} Please try another card.</strong>
					</div>
					<form @submit="updatePayment">
						<div class="box">
							<card class='stripe-card'
								:class='{ complete }'
								:stripe='stripeKey'
								:options='stripeOptions'
								@change='complete = $event.complete' />
						</div>
						<div class="is-clearfix">
							<button class='button is-medium is-primary is-pulled-right' type="submit" :disabled='!complete'>Update Credit Card</button>
						</div>
					</form>
				</template>
			</div>
		</div>
		<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
	</div>
</section>
</div>
</template>

<script>
import {
	functions,
	firestore as db,
	collections
} from '@/firebase'

import { Card, createToken } from 'vue-stripe-elements-plus'

import stripeConfig from './stripeConfig'

import AddDancerUidToUrl from '@/mixins/AddDancerUidToUrl'
import LookupDancer from '@/components/LookupDancer'
import EmbededState from '@/mixins/EmbededState'

export default {
	data: function () {
		return {
			invalidLink: false,
			isLoading: false,
			dancer: null,

			stripeKey: stripeConfig.publishableKey,
			complete: false,
			stripeOptions: {},

			error: {
				message: null
			},
			success: false
		}
	},
	mounted: function () {
		if (!this.uid)
		{
			return
		}

		this.isLoading = true

		db.collection(collections.Dancers).doc(this.uid).get().then(snap => {
			if (!snap.exists)
			{
				this.invalidLink = true
				return
			}

			const data = snap.data()

			this.dancer = data
		})
		.then(() => {
			this.isLoading = false
		})
		.catch(console.error)
	},
	computed: {
		uid: function () {
			return this.$route.params.dancerUid
		}
	},
	methods: {
		handleDancerFound: function (dancer) {
			this.dancer = dancer

			if (!this.$route.params.dancerUid)
			{
				this.addDancerUidToUrl(this.dancer.uid)
			}
		},
		clearAll: function () {
			this.$router.push('/payments/update')
			this.dancer = null
		},
		updatePayment: function (evt) {
			evt.preventDefault()

			this.error.message = null

			this.isLoading = true

			createToken().then(data => {
				if (data.error)
				{
					this.isLoading = false
					this.error.message = data.error.message
					return
				}

				// send data.token.id to server to update stripe customer

				const updateCC = functions.httpsCallable('updatePayment')
				updateCC({
					token: data.token.id,
					dancerUid: this.uid
				})
				.then(res => {
					this.isLoading = false

					if (!res.data.success)
					{
						this.error.message = res.data.data.message
						return
					}

					this.success = true
				})
				.catch(err => {
					console.error(err)

					this.isLoading = false
					this.error.message = err
				})
			})
		},
		openEmail: function () {
			location.href = 'mailto:hello@brocheballet.com'
		}
	},
	components: {
		Card,
		LookupDancer
	},
	mixins: [
		AddDancerUidToUrl,
		EmbededState
	]
}
</script>

<style scoped>

</style>