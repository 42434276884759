import { Component, Vue } from 'vue-property-decorator'

import moment from 'moment'

@Component
export default class Formats extends Vue
{
	protected formatClass (item: IStudioClassExpanded)
	{
		return `${item.startTime.replace(' ', '')} ${item.classType.name} ${item.classLevel.name} with ${item.instructor.name} at ${item.studio.name} on ${item.dayOfWeek}s`
	}

	protected classTitle(item: IStudioClassExpanded)
	{
		return `${item.startTime.replace(' ', '')} ${item.classType.name} ${item.classLevel.name}`
	}

	protected formatDate(date: Date)
	{
		return moment(date).format('ddd, MMM Do YYYY')
	}

	protected formatDateFull(date: Date)
	{
		return moment(date).format('dddd, MMMM Do YYYY')
	}

	protected formatDateNoDay(date: Date)
	{
		return moment(date).format('MMMM Do YYYY')
	}
}

