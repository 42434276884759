var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.embeded
      ? _c("section", { staticClass: "hero has-background-grey-dark" }, [
          _vm._m(0)
        ])
      : _vm._e(),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !(_vm.uid && _vm.isLoading),
                expression: "!(uid && isLoading)"
              }
            ],
            staticClass: "box"
          },
          [
            _c("lookup-dancer", {
              ref: "lookupDancer",
              attrs: { hideStepWhenFound: true, dancer: _vm.dancer },
              on: { clear: _vm.clearAll, dancerFound: _vm.handleDancerFound }
            })
          ],
          1
        ),
        _vm.dancer
          ? _c("div", { staticClass: "columns is-multiline" }, [
              _c("div", { staticClass: "column is-half" }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    staticStyle: { "min-height": "200px" }
                  },
                  [
                    _c("p", { staticClass: "is-size-4" }, [
                      _vm._v("Need to miss a class?")
                    ]),
                    _c("p", { staticStyle: { "min-height": "50px" } }, [
                      _vm._v(
                        " Click the button below to let us know which class you'll be missing. "
                      )
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-primary is-outlined has-text-dark is-medium is-fullwidth",
                        on: { click: _vm.handleScheduleMissedClass }
                      },
                      [_vm._v(" Schedule Missed Classes ")]
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column is-half" }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    staticStyle: { "min-height": "200px" }
                  },
                  [
                    _c("p", { staticClass: "is-size-4" }, [
                      _vm._v("Have makeup credits to use?")
                    ]),
                    _c("p", { staticStyle: { "min-height": "50px" } }, [
                      _vm._v(
                        " Click the button below to schedule your makeup classes. "
                      )
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button is-primary is-outlined has-text-dark is-medium is-fullwidth",
                        on: { click: _vm.handleScheduleMakeups }
                      },
                      [_vm._v(" Schedule Makeups ")]
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container has-text-centered" }, [
        _c("img", {
          staticStyle: { "max-height": "150px" },
          attrs: { src: require("../assets/logo.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }