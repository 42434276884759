<template>
<div>
<section class="hero has-background-grey-dark">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../../assets/logo.png" style="max-height: 150px" />
		</div>
	</div>
</section>

<section class="section">
<div class="container">

	<div v-if="invalidLink">
		<article class="message is-warning">
			<div class="message-header">
				<h1 class="is-size-3">Looks like you have a broken link...</h1>
			</div>
		</article>
	</div>

	<div v-if="state === 'done'">
		<article class="message is-success">
			<div class="message-header">
				<h1 class="is-size-3">You are all set!</h1>
			</div>
			<div class="message-body">
				<p>
					<strong>Thanks for letting us know you'll be missing class.</strong>
				</p>
				<br>
				<p>
					<router-link :to="'/schedule/makeups/' + uid">Click here to schedule your makeups when you are ready!</router-link>
				</p>
				<br>
				<p>
					<strong>Don't forget -</strong> if this time no longer works for you, we have lots of classes available and are happy to help you find a better fit.
				</p>
				<br>
				<p>
					<a @click="handleEmail">Just let us know!</a>
				</p>
			</div>
		</article>
	</div>

	<div v-if="dancer && state !== 'done'">
		<h3 class="is-size-3">
			Hi {{ dancer.firstName }},
			<p class="subtitle">
				Let's confirm which class you'll be missing:
			</p>
		</h3>
		<br>
		<template v-for="item in appointments">
		<div v-if="!isLoading && appointments.length > 0" :key="item.id" class="box">
			<div class="is-clearfix" style="height: 0px">
				<div class="tags is-pulled-right">
					<span v-if="isToday(item)" class="tag is-info">Today</span>
					<span v-if="item.classData.makeupUid" class="tag is-dark">Makeup</span>
					<span v-else-if="item.classData.freeUid" class="tag is-warning">Free</span>
				</div>
			</div>
			<div class="columns">
				<div class="column" :class="{'is-1':!item.missed,'is-2':item.missed}" style="margin-right: 5px">
					<div class="field" v-if="!item.missed">
						<b-switch v-model="missState[item.id]" size="is-medium"></b-switch>
					</div>
					<span v-if="item.missed" class="tag is-warning">Confirmed</span>
				</div>
				<div class="column" style="display: flex; align-items: center">
					{{ formatClass(item) }}
				</div>
			</div>
		</div>
		</template>
		<div class="is-clearfix">
			<button @click="handleConfirm" 
					class="button is-large is-primary is-pulled-right"
					:disabled="!hasSelected"
					:class="{'is-loading' : state === 'working'}">
				Confirm
			</button>
		</div>
		<div class="has-text-centered">
			<br>
			<p>
				<button @click="handleScheduleMakeups" class="button is-text">Click here to schedule makeups when you are ready!</button>
			</p>
		</div>
	</div>
	<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</section>

</div>
</template>

<script>
import {
	//functions,
	firestore as db,
	collections
} from '../../firebase'

import map from 'lodash/map'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import find from 'lodash/find'
import concat from 'lodash/concat'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import moment from 'moment'

import deleteMakeup from 'studio-shared/utils/deleteMakeup'

// import asyncResult from '@/utils/asyncResult'

import loader from '@/dataLoader'
// import { activeAndJoinClassesForDancer } from 'studio-shared/classes/classesForDancer'

const AppointmentsGenerator = require('studio-shared/appointments')

import store from '@/store'

import { getMakeups } from '@/database/makeups'

const appointments = new AppointmentsGenerator({
	getClass: uid => store.getters.class(uid),
	missedClassesForDate: date => store.getters['missedClassesByDate/forDate'](date),
	makeupsForDate: date => store.getters['makeupsByDate/forDate'](date),
	freeClassesForDate: date => store.getters['freeClassesByDate/forDate'](date),

	dancersForClass: uid => store.getters.dancersForClass(uid),
	joinsForClass: uid => store.getters['eventsByClass/joinsForClass'](uid),
	leavesForClass: uid => store.getters['eventsByClass/leavesForClass'](uid)
})

// const getMakeups = (dancerUid, today, onData) => {
// 	return db.collection(collections.MissedClasses).doc(dancerUid).collection('makeups')
// 		.where('makeupDate', '>=', today)
// 		.get().then(snap => {
// 			if (snap.empty)
// 			{
// 				return []
// 			}

// 			return map(snap.docs, doc => {
// 				const d = doc.data()
// 				d.uid = doc.id
				
// 				if (onData)
// 				{
// 					onData(d)
// 				}

// 				return {
// 					classUid: head(values(d.makeupClass)),
// 					appointmentDate: moment.unix(d.makeupDate.seconds).startOf('day').toDate(),
// 					makeupUid: doc.id,
// 					type: 'makeup'
// 				}
// 			})
// 		})
// }

/**
 * these are missed makeups or free classes
 */
const getMissedExtras = (dancerUid, today, onData) => {
	return db.collection(collections.MissedClasses).doc(dancerUid).collection('missed')
		.where('missedDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			const missed = []
			forEach(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id

				if (onData)
				{
					onData(d)
				}

				if (d.makeupUid || d.freeUid)
				{
					const mc = {
						classUid: d.missedClass,
						appointmentDate: moment.unix(d.missedDate.seconds).toDate(),
						missedUid: d.uid,
						type: 'missed'
					}

					mc.freeUid = d.freeUid
					mc.makeupUid = d.makeupUid

					missed.push(mc)
				}
				
			})

			return missed
		})
}

const getFreeClasses = (dancerUid, today, onData) => {
	return db.collection(collections.FreeClasses).doc(dancerUid).collection('classes')
		.where('classDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			const freeClasses = []

			forEach(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id

				if (onData)
				{
					onData(d)
				}

				freeClasses.push({
					classUid: d.classUid,
					appointmentDate: moment.unix(d.classDate.seconds).toDate(),
					freeUid: d.uid,
					type: 'free'
				})
			})

			return freeClasses
		})
}

export default {

components: {},

data: function () {
	return {
		invalidLink: false,
		isLoading: false,

		dancer: null,
		appointments: [],

		makeups: {},
		missedClasses: [],
		freeClasses: {},

		missState: {},

		state: 'idle'
	}
},
mounted: async function () {
	if (!this.uid)
	{
		this.invalidLink = true
		return
	}

	this.retrieveAppointments()
},
computed: {
	uid: function () {
		if (this.dancer)
		{
			return this.dancer.uid
		}
		return this.$route.params.dancerUid
	},
	hasSelected: function () {
		return find(this.missState, v => v)
	},

	appointmentsByDate: function () {
		return groupBy(this.appointments, item => item.date)
	}
},
methods: {
	generateAppointments: async function (classData, date) {
		await Promise.all([
			loader.fetchAndListenForMissedClassesByDate(date),
			loader.fetchAndListenForMakeupsByDate(date),
			loader.fetchAndListenForFreeClassesByDate(date),
			loader.fetchAndListenForDancerEventsByClass(classData.uid)
		])
		
		const appt = appointments.generateClassDataForDate(classData, date, this.uid, {
			makeups: this.$store.getters.makeupsForDancer(this.uid),
			freeClasses: this.$store.getters['freeClasses/forDancer'](this.uid)
		})

		const cd = clone(classData)
		cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
		cd.classType = this.$store.getters.classType(cd.classType)
		cd.instructor = this.$store.getters.teacher(cd.instructor)
		cd.studio = this.$store.getters.studio(cd.studio)

		appt.classData = cd
		appt.id = `${cd.uid}-${date}`

		return appt
	},
	retrieveAppointments: async function () {
		this.isLoading = true

		const today = moment().startOf('day').toDate()
		
		let snap = await db.collection(collections.Dancers).doc(this.uid).get()

		if (!snap.exists)
		{
			this.invalidLink = true
			return Promise.reject()
		}

		this.dancer = snap.data()
		this.dancer.uid = snap.id

		snap = await db.collection(collections.DancersToClasses).doc(this.uid).get()

		if (!snap.exists)
		{
			return
		}
		
		const data = snap.data()

		const now = moment(today)

		const otherClasses = Promise.all([
			getMakeups(this.dancer.uid, today, d => {
				this.makeups[d.uid] = d
			}),
			/**
			 * these are missed makeups or free classes
			 */
			getMissedExtras(this.dancer.uid, today, d => {
				this.missedClasses.push(d)
			}),
			getFreeClasses(this.dancer.uid, today, d => {
				this.freeClasses[d.uid] = d
			})
		])

		let classes = map(data.classes, classUid => {
			return {
				classUid: classUid,
				type: 'normal'
			}
		})

		const results = await otherClasses

		const makeupClasses = results[0]
		const missedClasses = results[1]
		const freeClasses = results[2]

		classes = concat(classes, makeupClasses, missedClasses, freeClasses)

		let currentDate = moment(now).startOf('day')

		const appointments = []
		const classDataByUid = {}

		for (let i = 0; i < 14; ++i)
		{
			const list = await Promise.all(map(classes, async (data) => {
				let classData = null

				if (data.classUid in classDataByUid)
				{
					classData = classDataByUid[data.classUid]
				}
				else
				{
					const snap = await db.collection(collections.Classes).doc(data.classUid).get()
					classData = snap.data()
					classData.uid = snap.id
					// eslint-disable-next-line require-atomic-updates
					classDataByUid[classData.uid] = classData
				}
				
				if (classData.dayOfWeek !== currentDate.format('dddd'))
				{
					return null
				}

				const appt = await this.generateAppointments(classData, currentDate.toDate())
				const d = appt.classData

				d.makeupUid = data.makeupUid
				d.missedUid = data.missedUid
				d.freeUid = data.freeUid
				d.type = data.type

				return appt
			}))

			list.forEach(item => {
				if (!item)
				{
					return
				}
				appointments.push(item)
			})

			currentDate = currentDate.add(1, 'day')
		}

		if (this.missedClasses.length > 0)
		{
			forEach(appointments, item => {
				item.missed = find(this.missedClasses, m => {
					return m.missedClass === item.classData.uid && moment.unix(m.missedDate.seconds).isSame(moment(item.date))
				})

				if (item.missed)
				{
					this.missState[item.id] = !item.missed
				}
			})
		}

		this.appointments = filter(appointments, item => {
			const now = moment()
			const date = moment(item.date)

			if (date.isBefore(now, 'hours'))
			{
				return false
			}

			const diff = date.diff(moment().startOf('day'))
			return moment.duration(diff).asDays() < 30
		})

		if (this.appointments.length <= 0)
		{
			this.state = 'done'
		}

		this.appointments = sortBy(this.appointments, ['date', 'missed'])

		this.isLoading = false

		console.dir(this.appointmentsByDate)
	},

	handleConfirm: function (evt) {
		evt.preventDefault()

		this.state = 'working'

		const items = filter(this.appointments, item => this.missState[item.uid] && !item.missed)

		Promise.all(map(items, item => {

			const missed = {
				credits: item.classType.credits,
				creditsUsed: 0,
				missedClass: item.uid,
				missedDate: item.data,
				source: 'self-serve'
			}

			if (item.makeupUid)
			{
				missed.makeupUid = item.makeupUid
				missed.credits = 0
			}
			else if(item.freeUid)
			{
				missed.credits = 0
				missed.freeUid = item.freeUid
			}

			const ref = db.collection(collections.MissedClasses).doc(this.dancer.uid)
			return ref.collection('missed').add(missed).then(() => {
				return this.markMissed(missed)
			})
		}))
		.then(() => {
			this.state = 'done'
		})

	},
	handleEmail: function () {
		location.href = 'mailto:hello@brocheballet.com'
	},
	handleScheduleMakeups: function () {
		location.href = 'https://brocheballet.com/find-a-makeup-class/'
	},

	formatClass: function (item) {
		const appointmentDate = moment(item.date).format('dddd, MMMM Do YYYY')
		const classData = item.classData
		return `${classData.classType.name} ${classData.classLevel.name} @ ${classData.startTime} with ${classData.instructor.name} at ${classData.studio.name} on ${appointmentDate}`
	},
	isToday: function (item) {
		return moment(item.date).isSame(moment().startOf('day'), 'day')
	},

	markMissed: function (missed) {
		if (!missed.makeupUid && !missed.freeUid)
		{
			return Promise.resolve()
		}

		console.log(missed)

		var ref
		if (missed.makeupUid)
		{
			const mu = clone(this.makeups[missed.makeupUid])
			delete mu.uid

			ref = db.collection(collections.MissedClasses).doc(this.dancer.uid)
			
			return ref.collection('makeups-missed').doc(missed.makeupUid).set(mu).then(() => {
				return deleteMakeup(this.makeups[missed.makeupUid], this.dancer.uid)
			})
		}

		const fc = clone(this.freeClasses[missed.freeUid])
		delete fc.uid

		ref = db.collection(collections.FreeClasses).doc(this.dancer.uid)

		return ref.collection('missed').doc(missed.freeUid).set(fc).then(() => {
			return ref.collection('classes').doc(missed.freeUid).delete()
		})
	}
}

}
</script>

<style scoped>

</style>