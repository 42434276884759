import { Component, Vue } from 'vue-property-decorator'

@Component
export default class EmbededState extends Vue
{
	get embeded()
	{
		return this.$store.getters['embeded/isEmbeded']
	}

	mounted()
	{
		this.$store.commit('embeded/set', !!this.$route.query.embed)
	}
}
