import { Component, Vue } from 'vue-property-decorator'

@Component
export default class IsPrima extends Vue
{
	get isPrima()
	{
		const dancer = this.getDancer()

		if (!dancer)
		{
			return false
		}

		if (!dancer.tags)
		{
			dancer.tags = []
		}

		const val = !!dancer.tags.find(tag => tag.toLowerCase() === 'prima')

		return val
	}

	protected getDancer(): IDancer | null
	{
		return null
	}
}
