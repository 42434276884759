

































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import {
	firestore as db,
	collections
} from '../../firebase'

import reduce from 'lodash/reduce'
import map from 'lodash/map'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'
import flatten from 'lodash/flatten'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'

import moment, { Moment } from 'moment-timezone'

// import ClassProperties from '../../services/ClassProperties'

import Formats from '@/mixins/Formats'
import AddDancerUidToUrl from '@/mixins/AddDancerUidToUrl'
import ScrollToBottom from '@/mixins/ScrollToBottom'
import EmbededState from '@/mixins/EmbededState'
import IsPrima from '@/mixins/IsPrima'

import LookupDancer from '@/components/LookupDancer.vue'
import PrimaToggle from '@/components/PrimaToggle.vue'

import loader from '@/dataLoader'
import { activeAndJoinClassesForDancer } from 'studio-shared/classes/classesForDancer'

const AppointmentsGenerator = require('studio-shared/appointments')

import store from '@/store'

import { getMakeups } from '@/database/makeups'
import deleteMakeup from 'studio-shared/utils/deleteMakeup'

import { dateKeyFormat } from '@/utils/dateKeyFormat'

const appointments = new AppointmentsGenerator({
	getClass: (uid: string) => store.getters.class(uid),
	missedClassesForDate: (date: Date) => store.getters['missedClassesByDate/forDate'](date),
	makeupsForDate: (date: Date) => store.getters['makeupsByDate/forDate'](date),
	freeClassesForDate: (date: Date) => store.getters['freeClassesByDate/forDate'](date),

	dancersForClass: (uid: string) => store.getters.dancersForClass(uid),
	joinsForClass: (uid: string) => store.getters['eventsByClass/joinsForClass'](uid),
	leavesForClass: (uid: string) => store.getters['eventsByClass/leavesForClass'](uid)
})

import MakeupClassFinder from 'studio-shared/makeups'

const makeupClassFinder = new MakeupClassFinder({
	classesForDancer: (uid: string) => activeAndJoinClassesForDancer(uid, true),
	getClasses: () => store.getters.classes,
	getClass: (uid: string) => store.getters.class(uid),
	getClassLevel: (uid: string) => store.getters.classLevel(uid),
	getClassType: (uid: string) => store.getters.classType(uid)
})

const loadClassDependents = (uid: string) => {
	return Promise.all([
		loader.fetchAndListenForMissedClasses(uid),
		loader.fetchAndListenForFreeClasses(uid)
	])
}

const MakeupApplier = require('studio-shared/makeups/MakeupApplier')

const makeupApplier = new MakeupApplier(db, collections)

const getScrollTop = () => (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop

@Component({
	components: {
		LookupDancer,
		PrimaToggle
	}
})
export default class ScheduleMakeups extends Mixins(Formats, ScrollToBottom, AddDancerUidToUrl, EmbededState, IsPrima)
{
	private isLoading = false
	private state = 'idle'

	private dancer: IDancer | null = null

	private makeupAppointments: any[] = []

	private selectedAppointment: any = null
	private canceledAppointment: any = null

	private filter = {
		weekOptions: [
			{
				value: 0,
				name: 'this week'
			},
			{
				value: 1,
				name: 'next week'
			},
			{
				value: 2,
				name: 'in 2 weeks'
			},
			{
				value: 3,
				name: 'in 3 weeks'
			}
		],
		selectedWeek: 0,

		classOptions: [
			{
				value: 0,
				name: 'All Classes'
			}
		],
		selectedClassOption: 'All Classes'
	}

	private attachSecondStep = false
	private scheduleError = false

	private appointmentClassesAndDates: any[] = []

	get uid()
	{
		if (this.dancer)
		{
			return this.dancer!.uid
		}
		return this.$route.params.dancerUid
	}

	get missedClasses()
	{
		const list = this.$store.getters.missedClassesForDancer(this.uid)
		
		const classes = map(list,
			(data: any) => {
				data.classData = this.$store.getters.class(data.missedClass)

				return data
			}
		)

		return classes
	}

	get missedClassesCreditsCount()
	{
		return reduce(this.missedClasses, (sum: number, obj: any) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get extraCredits()
	{
		const list = this.$store.getters['extraCredits/forDancer'](this.uid)

		return list
	}

	get extraCreditsCount()
	{
		return reduce(this.extraCredits, (sum: number, obj: any) => sum += (obj.credits - obj.creditsUsed), 0)
	}

	get creditsRemaining()
	{
		return this.missedClassesCreditsCount + this.extraCreditsCount
	}

	get makeupClassData()
	{
		if (!this.uid)
		{
			return []
		}

		return makeupClassFinder.makeupClassDataForDancer(this.uid)
	}

	get potentialClasses()
	{
		if (!this.dancer || !this.dancer.uid)
		{
			return []
		}

		// return makeupClassFinder.makeupClasses(this.makeupClassData, this.maxCredits)
		return makeupClassFinder.makeupClasses(this.makeupClassData)
	}

	get makeupsForDancer()
	{
		if (!this.uid)
		{
			return []
		}
		return this.$store.getters.makeupsForDancer(this.uid)
	}

	get extraClassesByDate()
	{
		const ret: any = {}

		forEach(this.appointmentClassesAndDates, (data: any) => {
			const date = data.date

			if (!ret.makeups)
			{
				ret.makeups = {}
			}

			ret.makeups[date] = this.$store.getters['makeupsByDate/forDate'](date)

			if (!ret.freeClasses)
			{
				ret.freeClasses = {}
			}

			ret.freeClasses[date] = this.$store.getters['freeClassesByDate/forDate'](date)
		})

		return ret
	}

	get filteredAppointments()
	{
		const week = moment().tz('America/Denver').startOf('week').add(this.filter.selectedWeek, 'w')
		
		const list = filter(this.makeupAppointments, (appt: any) => {
			if (this.hasPassedTime(appt))
			{
				return false
			}

			const apptDate = moment(appt.date)

			const started = moment(appt.classData.startDate).isSameOrBefore(apptDate, 'day')
			if (!started)
			{
				return false
			}

			const isActive = appt.classData.status === 'Active'
			var discontinuedButActive = false
			if (appt.classData.endDate)
			{
				discontinuedButActive = appt.classData.status === 'Discontinued' && moment(appt.classData.endDate).isSameOrAfter(apptDate, 'day')
			}
			
			if (!(isActive || discontinuedButActive))
			{
				return false
			}

			const sameWeek = apptDate.isSame(week, 'week')
			if (this.filter.selectedClassOption === 'All Classes')
			{
				return sameWeek
			}

			return started && sameWeek && appt.classData.classType.name === this.filter.selectedClassOption
		})

		if (list.length <= 0)
		{
			this.scrollToBottom()
		}

		if (getScrollTop() > 460)
		{
			window.scrollTo(0, 461)
		}

		return list
	}

	get closureDates()
	{
		const now = moment().tz('America/Denver')
		var closures = this.$store.getters['closures/closures'](now.format('YYYY'))
		if (now.month() >= 10)
		{
			const nextYear = now.year() + 1
			closures = concat(closures, this.$store.getters['closures/closures'](nextYear.toString()))
		}

		const lookup: any = {}

		forEach(closures, d => {
			lookup[d.format('YYYY-MM-DD')] = true
		})
		
		return lookup
	}

	@Watch('makeupsForDancer')
	private onMakeupsForDancerChanged()
	{
		this.generateMakeupAppointments().then(data => {

			this.makeupAppointments = data.makeupAppointments

			this.mergeWithClassOptionsFilter(data.classTypes)
		})
	}
		
	@Watch('extraClassesByDate')
	private onExtraClassesByDate()
	{
		this.generateMakeupAppointments().then(data => {

			this.makeupAppointments = data.makeupAppointments

			this.mergeWithClassOptionsFilter(data.classTypes)
		})
	}

	protected getDancer()
	{
		return this.dancer
	}

	mounted()
	{
		window.onscroll = () => {
			this.attachSecondStep = getScrollTop() > 460
		}

		if (!this.uid)
		{
			return
		}

		if (this.dancer)
		{
			return
		}

		this.isLoading = true

		// @ts-ignore
		this.$refs.lookupDancer.lookupDancerByUid(this.uid).then(() => {
			loader.fetchAndListenForDancerEvents(this.uid)
		})
		.catch((err: Error) => {
			console.log(err)
			this.isLoading = false
		})
	}

	private handleSelect(item: any)
	{
		this.selectedAppointment = item

		this.scrollToBottom()
	}

	private async handleCancelAppointment(item: any)
	{
		this.isLoading = true

		const dbData: { [key: string]: IMakeup } = {}

		const makeups: IAppointment[] = await getMakeups(this.uid, item.date, (d: IMakeup) => {
			dbData[d.uid] = d
		})

		const makeupData = makeups.find(mu => mu.classUid === item.classData.uid)

		if (!makeupData)
		{
			this.isLoading = false
			return
		}
		
		const makeupDbData = dbData[makeupData.makeupUid]
		const mu = clone(makeupDbData)

		if (mu.uid)
		{
			delete mu.uid
		}

		const ref = db.collection(collections.MissedClasses).doc(this.uid)
		
		await ref.collection('makeups-missed').doc(makeupData.makeupUid).set(mu)

		await deleteMakeup(makeupDbData, this.uid)

		this.isLoading = false

		this.canceledAppointment = item

		this.state = 'canceled'
	}

	private handleDancerFound(dancer: IDancer)
	{
		this.dancer = dancer

		if (!this.$route.params.dancerUid)
		{
			this.addDancerUidToUrl(this.uid)
		}

		loader.fetchAndListenForMakeups(this.uid)
		loader.fetchAndListenForExtraCredits(this.uid)
		loader.fetchAndListenForDancerEvents(this.uid)

		this.isLoading = true

		if (this.$store.getters.classes.length > 0)
		{
			loadClassDependents(this.uid).then(this.generateMakeups)
				.catch(err => {
					console.log(err)
					this.isLoading = false
				})
		}
		else
		{
			loader.once('classes-loaded').then(() => {
				return loadClassDependents(this.uid)
			})
			.then(this.generateMakeups)
			.catch((err: Error) => {
				console.log(err)
				this.isLoading = false
			})
		}
	}

	private generateMakeups()
	{
		this.isLoading = true

		this.appointmentClassesAndDates = []
		this.filter.classOptions = [
			this.filter.classOptions[0]
		]

		// go through all potential makeups and show the ones available next (closest one from today)
		// generate a date for each and generate an appointment for each date

		const now = moment().tz('America/Denver').startOf('day')

		forEach(this.potentialClasses, classData => {
			const dates = []
			var weeks = 4

			for (var i = 0; i < weeks; ++i)
			{
				var date = moment().tz('America/Denver').day(classData.dayOfWeek).startOf('day').add(i, 'w')
				if (date.isBefore(now, 'day'))
				{
					weeks += 1
					continue
				}

				dates.push(date.toDate())
			}

			this.appointmentClassesAndDates.push({
				classData: classData,
				dates: dates
			})
		})

		this.generateMakeupAppointments().then(data => {

			this.makeupAppointments = data.makeupAppointments

			this.mergeWithClassOptionsFilter(data.classTypes)

			this.isLoading = false
		})
	}

	private async generateAppointments(classData: any, date: Moment)
	{
		await Promise.all([
			loader.fetchAndListenForMissedClassesByDate(date),
			loader.fetchAndListenForMakeupsByDate(date),
			loader.fetchAndListenForFreeClassesByDate(date),
			loader.fetchAndListenForDancerEventsByClass(classData.uid),
			loader.fetchAndListenForSubstitutes(classData.uid)
		])

		const appt = appointments.generateClassDataForDate(classData, date, this.uid, {
			makeups: this.$store.getters.makeupsForDancer(this.uid),
			freeClasses: this.$store.getters['freeClasses/forDancer'](this.uid)
		})

		const cd = clone(classData)
		cd.classLevel = this.$store.getters.classLevel(cd.classLevel)
		cd.classType = this.$store.getters.classType(cd.classType)
		cd.instructor = this.$store.getters.teacher(cd.instructor)
		cd.studio = this.$store.getters.studio(cd.studio)

		appt.classData = cd
		appt.id = `${cd.uid}-${date}`

		const subs: ISubstitute[] = this.$store.getters['substitutes/forDate'](date)
		const activeSub = subs.find(sub => sub.classId === classData.uid)

		if (activeSub)
		{
			appt.substitute = {
				uid: activeSub.uid,
				instructor: this.$store.getters.teacher(activeSub.instructor)
			}
		}

		return appt
	}

	private generateMakeupAppointments()
	{
		// stores all possible classTypes as a map; keys used in filtering classes by class type
		const classTypes: any = {}

		return Promise.all(map(this.appointmentClassesAndDates, data => {

			return Promise.all(map(data.dates, (date: Moment) => {
				return this.generateAppointments(data.classData, date)
					.then(appt => {
						classTypes[appt.classData.classType.name] = true

						return appt
					})
			}))
		}))
		.then(results => flatten(results))
		.then(results => {
			return {
				makeupAppointments: sortBy(results, 'date'),
				classTypes: keys(classTypes)
			}
		})
	}

	private mergeWithClassOptionsFilter(types: any[])
	{
		const list = [
			this.filter.classOptions[0]
		]

		const startLength = list.length
		for(var i = startLength; i < startLength + types.length; ++i)
		{
			list.push({
				value: i,
				name: types[i - startLength]
			})
		}

		this.filter.classOptions = list
	}

	private clearSelectedAppointment()
	{
		this.selectedAppointment = null
	}

	private async handleSchedule(evt: MouseEvent)
	{
		evt.preventDefault()

		this.isLoading = true

		const classData = this.selectedAppointment.classData

		let creditsCost = classData.classType.credits
		if (this.isPrima)
		{
			creditsCost = Math.min(creditsCost, this.creditsRemaining)
		}

		const makeup = {
			type: 'normal',
			credits: creditsCost,
			makeupClass: { uid: classData.uid },	//class needs uid
			makeupDate: this.selectedAppointment.date,
			maxMakeups: classData.maxMakeups,
			source: 'self-serve'
		}

		try
		{
			if (creditsCost <= 0)
			{
				await makeupApplier.applyFreeMakeup(makeup, this.dancer)
			}
			else
			{
				await makeupApplier.applyAutomatically(makeup, this.missedClasses, this.uid, this.extraCredits)
			}

			// just for slightly nice ux by giving it a second to recalculate
			setTimeout(() => {
				this.isLoading = false

				this.state = 'done'
			}, 1000)
		}
		catch (err)
		{
			console.error(err)

			this.isLoading = false
			this.scheduleError = true
		}
	}

	private handleStartOver()
	{
		this.scheduleError = false
		this.selectedAppointment = null
		if (this.canceledAppointment)
		{
			this.generateMakeups()
			this.canceledAppointment = null
		}
		this.removeClassTypeFilter()
	}

	private removeClassTypeFilter()
	{
		this.filter.selectedClassOption = this.filter.classOptions[0].name
	}

	private removeClassWeekFilter()
	{
		this.filter.selectedWeek = this.filter.weekOptions[0].value
	}

	private handleScheduleAnother()
	{
		this.state = 'idle'
		this.handleStartOver()
		this.removeClassWeekFilter()
	}

	private clearAll()
	{
		this.$router.push('/schedule/makeups')
		this.dancer = null
		this.handleStartOver()
	}

	private titleForClass(classData: any)
	{
		var title = classData.classType.name
		if (classData.classLevel.name !== 'Open')
		{
			title += ` ${classData.classLevel.name}`
		}
		return title
	}

	private pluralOrSingle(base: string, count: number)
	{
		if (count === 1)
		{
			return base
		}

		return base + 's'
	}

	private hasPassedTime(item: any)
	{
		const now = moment().tz('America/Denver')
		const date = moment(item.date)

		if (now.isBefore(date, 'day'))
		{
			return false
		}

		const startTime = item.classData.startTime

		const parts = startTime.split(' ')
		const isPm = parts[1] === 'pm'

		const timeParts = parts[0].split(':')
		var hour = parseInt(timeParts[0])
		if (isPm)
		{
			hour += 12
		}
		const minutes = parseInt(timeParts[1])

		date.hour(hour).minute(minutes)

		return now.isSameOrAfter(date, 'hour')
	}

	private isStudioClosed(date: Date)
	{
		const key = dateKeyFormat(date)
		return this.closureDates[key]
	}
}
