<template>
<section class="hero is-white is-fullheight">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../assets/logo_black.png" style="max-height: 150px" />
		</div>
	</div>
</section>
</template>

<script>
export default {
	data: function () {
		return {}
	}
}
</script>

<style scoped>

</style>
