import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'

import config from './config'

const firebaseApp = firebase.initializeApp(config)

const firestore = firebase.firestore()
const functions = firebase.functions()
const auth = firebase.auth()

const collections = require('studio-shared/database/collections')

export default firebaseApp

export { 
	firestore,
	functions,
	auth,
	collections
}