import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import VeeValidate from 'vee-validate'

import VueClipboard from 'vue-clipboard2'
import 'vuex'

Vue.use(Buefy, {
	defaultIconPack: 'fas'
})

Vue.use(VeeValidate, {
	fieldsBagName: 'veeFields'
})

Vue.use(VueClipboard)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import loader from './dataLoader'

loader.fetchAndListenForClasses()
loader.fetchAndListenForDancersToClasses()
loader.fetchAndListenForClosures()

// also load next year if we are in november
import moment from 'moment'
const now = moment()
if (now.month() >= 10)
{
	const nextYear = now.year() + 1
	loader.fetchAndListenForClosures(nextYear.toString())
}
