<template>
<div>
<section class="hero has-background-grey-dark">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../../assets/logo.png" style="max-height: 150px" />
		</div>
	</div>
</section>

<section class="section">
<div class="container">

	<div v-if="invalidLink">
		<article class="message is-warning">
			<div class="message-header">
				<h1 class="is-size-3">Looks like you have a broken link...</h1>
			</div>
		</article>
	</div>

	<div v-if="state === 'done'">
		<article class="message is-success">
			<div class="message-header">
				<h1 class="is-size-3">You are all set!</h1>
			</div>
			<div class="message-body">
				<p>
					<strong>Thanks for letting us know you'll be missing class.</strong>
				</p>
				<br>
				<p>
					<router-link :to="'/schedule/makeups/' + uid">Click here to schedule your makeups when you are ready!</router-link>
				</p>
				<br>
				<p>
					<strong>Don't forget -</strong> if this time no longer works for you, we have lots of classes available and are happy to help you find a better fit.
				</p>
				<br>
				<p>
					<a @click="handleEmail">Just let us know!</a>
				</p>
			</div>
		</article>
	</div>

	<div v-if="dancer && state !== 'done'">
		<h3 class="is-size-3">
			Hi {{ dancer.firstName }},
			<p class="subtitle">
				Let's confirm which class you'll be missing:
			</p>
		</h3>
		<br>
		<template v-if="!isLoading && classes.length > 0">
			<div v-for="item in classes" :key="item.uid" class="box">
				<div class="is-clearfix" style="height: 0px">
					<div class="tags is-pulled-right">
						<span v-if="isToday(item)" class="tag is-info">Today</span>
						<span v-if="item.makeupUid" class="tag is-dark">Makeup</span>
						<span v-else-if="item.freeUid" class="tag is-warning">Free</span>
					</div>
				</div>
				<div class="columns">
					<div class="column" :class="{'is-1':!item.missed,'is-2':item.missed}" style="margin-right: 5px">
						<div class="field" v-if="!item.missed">
							<b-switch v-model="missState[item.uid]" size="is-medium"></b-switch>
						</div>
						<span v-if="item.missed" class="tag is-warning">Confirmed</span>
					</div>
					<div class="column" style="display: flex; align-items: center">
						{{ formatClass(item) }}
					</div>
				</div>
			</div>
		</template>
		<div class="is-clearfix">
			<button @click="handleConfirm" 
					class="button is-large is-primary is-pulled-right"
					:disabled="!hasSelected"
					:class="{'is-loading' : state === 'working'}">
				Confirm
			</button>
		</div>
		<div class="has-text-centered">
			<br>
			<p>
				<button @click="handleScheduleMakeups" class="button is-text">Click here to schedule makeups when you are ready!</button>
			</p>
		</div>
	</div>
	<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</section>

</div>
</template>

<script>
import {
	//functions,
	firestore as db,
	collections
} from '../../firebase'

import map from 'lodash/map'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import find from 'lodash/find'
import toLower from 'lodash/toLower'
import values from 'lodash/values'
import head from 'lodash/head'
import concat from 'lodash/concat'
import clone from 'lodash/clone'
import sortBy from 'lodash/sortBy'

import moment from 'moment-timezone'

import { getLookups } from '../../services/ClassProperties'

import deleteMakeup from 'studio-shared/utils/deleteMakeup'

const getMakeups = (dancerUid, today, onData) => {
	return db.collection(collections.MissedClasses).doc(dancerUid).collection('makeups')
		.where('makeupDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			return map(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id
				
				if (onData)
				{
					onData(d)
				}

				return {
					classUid: head(values(d.makeupClass)),
					appointmentDate: moment.unix(d.makeupDate.seconds).startOf('day').toDate(),
					makeupUid: doc.id,
					type: 'makeup'
				}
			})
		})
}

/**
 * these are missed makeups or free classes
 */
const getMissedExtras = (dancerUid, today, onData) => {
	return db.collection(collections.MissedClasses).doc(dancerUid).collection('missed')
		.where('missedDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			const missed = []
			forEach(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id

				if (onData)
				{
					onData(d)
				}

				if (d.makeupUid || d.freeUid)
				{
					const mc = {
						classUid: d.missedClass,
						appointmentDate: moment.unix(d.missedDate.seconds).toDate(),
						missedUid: d.uid,
						type: 'missed'
					}

					mc.freeUid = d.freeUid
					mc.makeupUid = d.makeupUid

					missed.push(mc)
				}
				
			})

			return missed
		})
}

const getFreeClasses = (dancerUid, today, onData) => {
	return db.collection(collections.FreeClasses).doc(dancerUid).collection('classes')
		.where('classDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			const freeClasses = []

			forEach(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id

				if (onData)
				{
					onData(d)
				}

				freeClasses.push({
					classUid: d.classUid,
					appointmentDate: moment.unix(d.classDate.seconds).toDate(),
					freeUid: d.uid,
					type: 'free'
				})
			})

			return freeClasses
		})
}

export default {
	data: function () {
		return {
			invalidLink: false,
			isLoading: false,

			dancer: null,
			classes: [],

			makeups: {},
			missedClasses: [],
			freeClasses: {},

			missState: {},

			state: 'idle'
		}
	},
	mounted: function () {
		if (!this.email)
		{
			this.invalidLink = true
			return
		}

		this.isLoading = true

		const today = moment().tz('America/Denver').startOf('day').toDate()
		
		getLookups().then(lookups => {
			return db.collection(collections.Dancers).where('email', '==', this.email).get().then(snap => {
				if (snap.empty)
				{
					this.invalidLink = true
					return Promise.reject()
				}

				const doc = snap.docs[0]

				this.dancer = doc.data()
				this.dancer.uid = doc.id

				return db.collection(collections.DancersToClasses).doc(this.uid).get()
			})
			.then(snap => {
				if (!snap.exists)
				{
					return
				}
				
				const data = snap.data()
				const now = moment(today)

				return Promise.all([
					getMakeups(this.dancer.uid, today, d => {
						this.makeups[d.uid] = d
					}),
					/**
					 * these are missed makeups or free classes
					 */
					getMissedExtras(this.dancer.uid, today, d => {
						this.missedClasses.push(d)
					}),
					getFreeClasses(this.dancer.uid, today, d => {
						this.freeClasses[d.uid] = d
					})
				])
				.then(results => {
					const classes = map(data.classes, classUid => {
						return {
							classUid: classUid,
							type: 'normal'
						}
					})

					const makeupClasses = results[0]
					const missedClasses = results[1]
					const freeClasses = results[2]

					return concat(classes, makeupClasses, missedClasses, freeClasses)
				})
				.then(classes => {
					return Promise.all(map(classes, classData => {
						const classUid = classData.classUid
						return db.collection(collections.Classes).doc(classUid).get().then(snap => {
							if (!snap.exists)
							{
								return {}
							}

							const d = snap.data()
							d.uid = snap.id

							d.classType = lookups.classTypes[d.classType]
							d.classLevel = lookups.classLevels[d.classLevel]
							d.instructor = lookups.teachers[d.instructor]
							d.studio = lookups.studios[d.studio]
							d.makeupUid = classData.makeupUid
							d.missedUid = classData.missedUid
							d.freeUid = classData.freeUid
							d.type = classData.type

							if (!classData.appointmentDate)
							{
								var appointmentDate = moment().tz('America/Denver').day(d.dayOfWeek).startOf('day').add(0, 'w')
								
								if (appointmentDate.isBefore(now, 'day'))
								{
									appointmentDate.add(1, 'w')
								}

								d.appointmentDate = appointmentDate.toDate()
							}
							else
							{
								d.appointmentDate = classData.appointmentDate
							}

							this.$set(this.missState, d.uid, this.isToday(d))

							return d
						})
					}))
				})
			})
			.then(ret => {
				if (this.missedClasses.length <= 0)
				{
					return ret
				}

				forEach(ret, item => {
					item.missed = find(this.missedClasses, m => {
						return m.missedClass === item.uid && moment.unix(m.missedDate.seconds).isSame(moment(item.appointmentDate))
					})

					if (item.missed)
					{
						this.missState[item.uid] = !item.missed
					}
				})

				return ret
			})
			.then(ret => {
				this.classes = filter(ret, item => {
					const diff = moment(item.appointmentDate).diff(moment().tz('America/Denver').startOf('day'))
					return moment.duration(diff).asDays() < 2
				})

				if (this.classes.length <= 0)
				{
					this.state = 'done'
				}

				this.classes = sortBy(this.classes, ['appointmentDate', 'missed'])

				this.isLoading = false
			})
		})
		.catch(err => {
			console.error(err)

			this.isLoading = false
		})

	},
	computed: {
		uid: function () {
			if (!this.dancer)
			{
				return null
			}

			return this.dancer.uid
		},
		email: function () {
			return toLower(this.$route.params.email)
		},
		hasSelected: function () {
			return find(this.missState, v => v)
		}
	},
	methods: {
		handleConfirm: function (evt) {
			evt.preventDefault()

			this.state = 'working'

			const items = filter(this.classes, item => this.missState[item.uid] && !item.missed)

			Promise.all(map(items, item => {

				const missed = {
					credits: item.classType.credits,
					creditsUsed: 0,
					missedClass: item.uid,
					missedDate: item.appointmentDate,
					source: 'self-serve'
				}

				if (item.makeupUid)
				{
					missed.makeupUid = item.makeupUid
					missed.credits = 0
				}
				else if(item.freeUid)
				{
					missed.credits = 0
					missed.freeUid = item.freeUid
				}

				const ref = db.collection(collections.MissedClasses).doc(this.dancer.uid)
				return ref.collection('missed').add(missed).then(() => {
					return this.markMissed(missed)
				})
			}))
			.then(() => {
				this.state = 'done'
			})

		},
		handleEmail: function () {
			location.href = 'mailto:hello@brocheballet.com'
		},
		handleScheduleMakeups: function () {
			location.href = 'https://brocheballet.com/find-a-makeup-class/'
		},

		formatClass: function (item) {
			const appointmentDate = moment(item.appointmentDate).format('dddd, MMMM Do YYYY')
			return `${item.classType.name} ${item.classLevel.name} @ ${item.startTime} with ${item.instructor.name} at ${item.studio.name} on ${appointmentDate}`
		},
		isToday: function (item) {
			return moment(item.appointmentDate).isSame(moment().tz('America/Denver').startOf('day'), 'day')
		},

		markMissed: function (missed) {
			if (!missed.makeupUid && !missed.freeUid)
			{
				return Promise.resolve()
			}

			console.log(missed)

			var ref
			if (missed.makeupUid)
			{
				const mu = clone(this.makeups[missed.makeupUid])
				delete mu.uid

				ref = db.collection(collections.MissedClasses).doc(this.dancer.uid)
				
				return ref.collection('makeups-missed').doc(missed.makeupUid).set(mu).then(() => {
					return deleteMakeup(this.makeups[missed.makeupUid], this.dancer.uid)
				})
			}

			const fc = clone(this.freeClasses[missed.freeUid])
			delete fc.uid

			ref = db.collection(collections.FreeClasses).doc(this.dancer.uid)

			return ref.collection('missed').doc(missed.freeUid).set(fc).then(() => {
				return ref.collection('classes').doc(missed.freeUid).delete()
			})
		}
	},
	components: {}
}
</script>

<style scoped>

</style>