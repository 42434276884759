import { classOverridesByDate } from '@/database'

import store from '../store'

import moment from 'moment'

export default (classId: string) => {
	return new Promise(resolve => {
		classOverridesByDate.doc(classId).collection('substitutes').onSnapshot(snap => {
			snap.docChanges().forEach(change => {
				const doc = change.doc
				const sub = doc.data()
				sub.uid = doc.id
				sub.date = moment(sub.uid).toDate()

				const data = {
					sub,
					classId
				}

				if (change.type === 'added') {
					store.commit('substitutes/add', data)
					return
				}

				if (change.type === 'modified') {
					store.commit('substitutes/update', data)
					return
				}

				if (change.type === 'removed') {
					store.commit('substitutes/remove', data)
					return
				}
			})

			resolve()
		})
	})
}
