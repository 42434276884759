import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AddDancerUidToUrl extends Vue
{
	protected addDancerUidToUrl(uid: string)
	{
		this.$router.replace({
			path: `${this.$route.path}/${uid}`,
			query: this.$route.query
		})
	}
}
