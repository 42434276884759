import {
	//functions,
	firestore as db,
	collections
} from '../firebase'

import map from 'lodash/map'
import head from 'lodash/head'
import values from 'lodash/values'

import moment from 'moment'

const getMakeups = (dancerUid, today, onData) => {
	return db.collection(collections.MissedClasses).doc(dancerUid).collection('makeups')
		.where('makeupDate', '>=', today)
		.get().then(snap => {
			if (snap.empty)
			{
				return []
			}

			return map(snap.docs, doc => {
				const d = doc.data()
				d.uid = doc.id
				
				if (onData)
				{
					onData(d)
				}

				return {
					classUid: head(values(d.makeupClass)),
					appointmentDate: moment.unix(d.makeupDate.seconds).startOf('day').toDate(),
					makeupUid: doc.id,
					type: 'makeup'
				}
			})
		})
}

export {
	getMakeups
}
