var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.embeded
      ? _c("section", { staticClass: "hero has-background-grey-dark" }, [
          _vm._m(0)
        ])
      : _vm._e(),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h1", { staticClass: "is-size-3" }, [
            _vm._v(" Makeup Credits Balance ")
          ]),
          _c("br"),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c("lookup-dancer", {
                ref: "lookupDancer",
                attrs: { dancer: _vm.dancer, hideStepWhenFound: true },
                on: { clear: _vm.clearAll, dancerFound: _vm.handleDancerFound }
              }),
              _vm.dancer
                ? [
                    _c("hr"),
                    _c("h1", { staticClass: "is-size-4" }, [
                      _vm._v(" You have "),
                      _c("u", [_vm._v(_vm._s(_vm.creditsRemaining))]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.pluralOrSingle("credit", _vm.creditsRemaining)
                          ) +
                          ". "
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container has-text-centered" }, [
        _c("img", {
          staticStyle: { "max-height": "150px" },
          attrs: { src: require("../../assets/logo.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }