import moment from 'moment'

const FORMAT = 'YYYY-MM-DD'

const dateKeyFormat = (date: Date | moment.Moment) => {
	if (moment.isDate(date))
	{
		return moment(date).format(FORMAT)
	}

	return date.format(FORMAT)
}


export {
	dateKeyFormat
}
