<template>
<div>
<section v-if="!embeded" class="hero has-background-grey-dark">
	<div class="hero-body">
		<div class="container has-text-centered">
			<img src="../../assets/logo.png" style="max-height: 150px" />
		</div>
	</div>
</section>

<section class="section">
<div class="container">

	<h1 class="is-size-3">
		Schedule a Missed Class 
	</h1>
	<br>

	<div v-if="state === 'done'">
		<article class="message is-success">
			<div class="message-header">
				<strong class="is-size-4">You're all set!</strong>
			</div>
			<div class="message-body">
				<p>
					<router-link :to="'/schedule/makeups/' + uid">Click here to schedule makeups when you are ready!</router-link>
				</p>
				<br>
				<button @click="handleScheduleAnother" class="button is-outlined is-medium is-dark is-fullwidth">
					Schedule Another
				</button>
			</div>
		</article>
	</div>

	<div v-else>

		<!-- select dancer -->
		<div class="box">
			<lookup-dancer ref="lookupDancer" :dancer="dancer" @clear="clearAll" @dancerFound="handleDancerFound" />
		</div>

		<!-- select class -->
		<div class="box" v-if="dancer">
			<div class="columns is-multiline">
				<div class="column" :class="{'is-1':!classIsSelected}">
					<span class="tag is-rounded is-large" :class="{'is-primary': !classIsSelected}">2</span>
					<span style="margin-left: 4.25%">
						<span v-if="selectClass.selected" class="is-size-5" :class="{'is-hidden-desktop': !classIsSelected, 'is-hidden-tablet': !classIsSelected}" style="margin: 0px">{{ classTitle(selectClass.selectedData) }}</span>
						<span v-if="!selectClass.selected" class="is-size-4 is-hidden-desktop is-hidden-tablet" style="margin: 0px">Select a Class</span>
					</span>
				</div>
				<div class="column" v-if="!classIsSelected">
					<h5 v-if="selectClass.selected" class="is-size-5 is-hidden-mobile" style="margin-bottom: 10px">{{ classTitle(selectClass.selectedData) }}</h5>
					<h4 v-if="!selectClass.selected" class="is-size-4 is-hidden-mobile" style="margin-bottom: 10px">Select a Class</h4>
					<div class="field" v-if="selectClass.selected">
						<div class="card">
							<div class="card-content">
								<a @click="clearSelectedClass" class="delete is-pulled-right is-medium"></a>
								<div class="columns is-multiline is-gapless">
									<div class="column is-full">
										{{ classTitle(selectClass.selectedData) }}
									</div>
									<div class="column is-full">
										at {{ selectClass.selectedData.studio.name }}
									</div>
									<div class="column is-full">
										with {{ selectClass.selectedData.instructor.name }}
									</div>
									<div class="column is-full">
										on {{ `${selectClass.selectedData.dayOfWeek}s` }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<b-field v-if="!selectClass.selected">
						<b-select placeholder="Which class will you be missing?" size="is-medium" 
								v-model="selectClass.selected"
								@input="handleClassSelected"
								expanded>
							<option v-for="item in selectClass.classes" :value="item.uid" :key="item.uid">
								{{ formatClass(item) }}
							</option>
						</b-select>
					</b-field>

					<div class="field">
						<div class="control">
							<button class="button is-medium is-primary is-fullwidth"
								@click="handleSelectClass"
								:class="{'is-loading': selectClass.state === 'working'}"
								v-show="selectClass.state !== 'success'"
								:disabled="!selectClass.selected">
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- select class -->
		<div class="box" v-if="classIsSelected">
			<div class="columns is-multiline">
				<div class="column" :class="{'is-1':classIsSelected}">
					<span class="tag is-rounded is-large" :class="{'is-primary': classIsSelected}">3</span>
					<span style="margin-left: 4.25%">
						<span v-if="selectDate.selected" class="is-size-5 is-hidden-desktop is-hidden-tablet" style="margin: 0px">{{ formatDate(selectDate.selected) }}</span>
						<span v-if="!selectDate.selected" class="is-size-4 is-hidden-desktop is-hidden-tablet" style="margin: 0px">Select Date</span>
					</span>
				</div>
				<div class="column">
					<h5 v-if="selectDate.selected" class="is-size-5 is-hidden-mobile" style="margin-bottom: 10px">{{ formatDate(selectDate.selected) }}</h5>
					<span v-if="!selectDate.selected" class="is-size-4 is-hidden-mobile" style="margin-bottom: 10px">Select Date</span>
					<b-field>
						<b-datepicker
							v-model="selectDate.selected"
							:selectable-dates="selectDate.options"
							:date-formatter="formatDate"
							@input="selectedDateChanged"
							placeholder="Which date will you be missing?"
							icon-pack="fas"
							icon="calendar">
						</b-datepicker>
					</b-field>
				</div>
			</div>
		</div>

		<div v-if="selectDate.selected">
			<div class="field">
				<div v-if="selectDate.error">
					<article class="message" style="margin-bottom: 15px"
							:class="selectDate.error.type ? selectDate.error.type : 'is-danger'">
						<div class="message-header">
							<strong>{{ selectDate.error.message }}</strong>
						</div>
					</article>
				</div>
				<div class="control">
					<button class="button is-medium is-primary is-fullwidth"
						@click="handleSchedule"
						:disabled="selectDate.error"
						:class="{'is-loading': state === 'working'}">
						Schedule
					</button>
				</div>
			</div>

			<div class="field">
				<div class="control">
					<button class="button is-outlined is-text is-fullwidth"
						@click="handleStartOver"
						:disabled="state === 'working'">
						Start Over
					</button>
				</div>
			</div>
		</div>

	</div>

	
	<b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
</div>
</section>

</div>
</template>

<script>
import {
	//functions,
	firestore as db,
	collections
} from '../../firebase'

import loader from '@/dataLoader'

import forEach from 'lodash/forEach'
import memoize from 'lodash/memoize'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'

import moment from 'moment-timezone'

import Formats from '@/mixins/Formats'
import AddDancerUidToUrl from '@/mixins/AddDancerUidToUrl'
import ScrollToBottom from '@/mixins/ScrollToBottom'
import EmbededState from '@/mixins/EmbededState'

import LookupDancer from '@/components/LookupDancer'

import { activeAndJoinClassesForDancer } from 'studio-shared/classes/classesForDancer'

export default {
	mixins: [
		Formats,
		AddDancerUidToUrl,
		ScrollToBottom,
		EmbededState
	],
	data: function () {
		return {
			isLoading: false,
			state: 'idle',

			selectClass: {
				classes: [],
				classesByUid: {},
				selected: null,
				selectedData: {},

				state: 'idle'
			},

			selectDate: {
				selected: null,
				options: [],
				error: null
			},

			dancer: null,
			scheduledMissedClasses: []
		}
	},
	mounted: function () {
		if (!this.uid)
		{
			return
		}

		this.isLoading = true
		this.$refs.lookupDancer.lookupDancerByUid(this.uid).then(dancer => {
			return this.handleDancerFound(dancer)
		})
		.then(() => {
			this.isLoading = false
		})
		.catch(err => {
			console.log(err)
			this.isLoading = false
		})
	},
	computed: {
		uid: function () {
			if (this.dancer)
			{
				return this.dancer.uid
			}
			return this.$route.params.dancerUid
		},

		classIsSelected: function () {
			return this.selectClass.state === 'done'
		}
	},
	methods: {
		handleDancerFound: function (dancer) {
			this.dancer = dancer

			this.listenForMissedClasses()

			var p = null
			if (this.$store.getters.classes.length > 0)
			{
				p = loader.fetchAndListenForDancerEvents(this.dancer.uid)
			}
			else
			{
				p = Promise.all([
					loader.fetchAndListenForDancerEvents(this.dancer.uid),
					loader.once('classes-loaded')
				])
			}

			return p.then(() => {
				return this.lookupClasses()
			})
			.then(classes => {
				return this.setupClasses(classes)
			})
			.then(() => {
				if (!this.$route.params.dancerUid)
				{
					this.addDancerUidToUrl(this.uid)
				}
			})
		},

		handleClassSelected: function (selected) {
			this.selectClass.selectedData = this.selectClass.classesByUid[selected]

			this.scrollToBottom()
		},
		handleSelectClass: function () {
			const item = this.selectClass.selectedData

			const nextDate = moment().tz('America/Denver').day(item.dayOfWeek).startOf('day').add(0, 'w')
			let leaveDate = null
			if (item.event)
			{
				if (item.event.action === 'Join Class')
				{
					const joinDate = moment(item.event.effectiveDate)

					while (nextDate.isBefore(joinDate))
					{
						nextDate.add(1, 'w')
					}
				}
				else if (item.event.action === 'Leave Class')
				{
					leaveDate = moment(item.event.effectiveDate)
				}
			}

			if (nextDate.isBefore(moment().tz('America/Denver'), 'day'))
			{
				nextDate.add(1, 'w')
			}

			this.selectDate.selected = nextDate.toDate()

			this.selectedDateChanged(this.selectDate.selected)

			for (var i = 0; i < 52; ++i)
			{
				if (leaveDate && nextDate.isAfter(leaveDate))
				{
					continue
				}

				this.selectDate.options.push(nextDate.toDate())
				nextDate.add(1, 'w')
			}

			this.scrollToBottom()

			this.selectClass.state = 'done'
		},
		handleSchedule: function (evt) {
			evt.preventDefault()

			const item = this.selectClass.selectedData
			const missed = {
				credits: item.classType.credits,
				creditsUsed: 0,
				missedClass: item.uid,
				missedDate: this.selectDate.selected,
				source: 'self-serve'
			}

			this.state = 'working'

			db.collection(collections.MissedClasses).doc(this.uid).collection('missed').add(missed).then(() => {
				this.state = 'done'
			})
			.catch(err => {
				console.error(err)
				this.state = 'idle'
			})
		},
		handleStartOver: function () {
			this.selectDate.selected = null
			this.selectDate.options = []
			this.clearSelectedClass()
			this.selectClass.state = 'idle'
		},
		handleScheduleAnother: function () {
			this.handleStartOver()
			this.state = 'idle'
		},

		lookupClasses: function () {
			const classes = map(
				activeAndJoinClassesForDancer(this.dancer.uid),
				item => {
					item.classType = this.$store.getters.classType(item.classType)
					item.classLevel = this.$store.getters.classLevel(item.classLevel)
					item.instructor = this.$store.getters.teacher(item.instructor)
					item.studio = this.$store.getters.studio(item.studio)

					return item
				}
			)

			return new Promise(resolve => {
				resolve(classes)
			})
		},
		setupClasses: function (classes) {
			this.selectClass.classes = classes

			forEach(classes, item => this.selectClass.classesByUid[item.uid] = item)

			return classes
		},

		clearSelectedClass: function () {
			this.selectClass.selected = null
			this.selectClass.selectedData = {}
		},
		clearAll: function () {
			this.$router.push('/schedule/miss/class')
			this.handleStartOver()
			this.dancer = null
		},
		selectedDateChanged: function (date) {
			
			const newDate = moment(date)

			this.scrollToBottom()

			const item = this.selectClass.selectedData
			const leaveDate = item.event && item.event.action === 'Leave Class' ? moment(item.event.effectiveDate) : null

			let afterLeaveDate = false
			if (leaveDate)
			{
				afterLeaveDate = newDate.isAfter(leaveDate)
			}

			if (newDate.format('dddd') !== this.selectClass.selectedData.dayOfWeek || afterLeaveDate)
			{
				this.selectDate.error = {
					message: 'Class does not meet on this date. Please select another date.'
				}

				return
			}

			// check against closures
			if (this.$store.getters['closures/isClosed'](newDate))
			{
				this.selectDate.error = {
					message: 'Studio is closed on this date and class will not meet. Please select another date.'
				}

				return
			}
			
			const idx = findIndex(this.scheduledMissedClasses, item => moment(item.missedDate).isSame(newDate, 'day'))
			if (idx >= 0)
			{
				this.selectDate.error = {
					message: 'You are all set. This missed class is already scheduled.',
					type: 'is-warning'
				}

				return
			}

			this.selectDate.error = null
		},

		listenForMissedClasses: memoize(function () {
			if (!this.uid)
			{
				return
			}

			const query = db.collection(collections.MissedClasses).doc(this.uid).collection('missed')
				.where('missedDate', '>=', moment().tz('America/Denver').startOf('day').toDate())

			query.onSnapshot(snap => {

				snap.docChanges().forEach(change => {
					const doc = change.doc
					const missed = doc.data()
					missed.uid = doc.id

					if (missed.missedDate)
					{
						missed.missedDate = moment.unix(missed.missedDate.seconds).toDate()
					}

					if (change.type === 'added') 
					{
						this.scheduledMissedClasses.push(missed)
						return
					}

					const idx = findIndex(this.scheduledMissedClasses, item => item.uid === missed.uid)
					if (change.type === 'modified') 
					{
						
						if (idx >= 0)
						{
							this.$set(this.scheduledMissedClasses, idx, missed)
						}
						return
					}

					if (change.type === 'removed') 
					{
						if (idx >= 0)
						{
							this.$delete(this.scheduledMissedClasses, idx)
						}
						return
					}
				})
			})
		})
	},
	components: {
		LookupDancer
	}
}
</script>

<style scoped>
.step-width {
	width: 60px
}
</style>